/**
 * This file contains a list of generic javascript functions that can be used
 * in any module/component of the application.
 */
import moment from "moment-timezone";

// updateSource() updates the given stream with new tracks OR
// returns an entirely new stream to the caller.
export function updateSource(stream, newTrack) {
  const existingTracks = stream?.getTracks() ?? [];
  // If the stream parameter contains no existing tracks,
  // just return a new MediaStream to set. This should
  // only happen the first time the tile is initialized.

  if ((!existingTracks || existingTracks.length === 0) && newTrack) {
    return new MediaStream([newTrack]);
  }

  if (existingTracks.length > 1) {
    console.warn(
      `expected 1 track, found ${existingTracks.length}. Only using the first one.`
    );
  }
  const existingTrack = existingTracks[0];
  // If existing track is different from the new track,
  // remove the existing track and add the new one.
  if (newTrack?.id !== existingTrack?.id) {
    stream.removeTrack(existingTrack);
    stream.addTrack(newTrack);
  }
  return null;
}

// Prints the duration of time in (x Hours xx Minutes) format from given datetimes
export function getDurationInHoursAndMinutes(startDateTime, endDateTime) {

  const start = moment(startDateTime);
  const end = moment(endDateTime);

  const duration = moment.duration(end.diff(start));

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  let result = "";

  if (hours > 0) {
      result += `${hours} `;
      if(hours > 1) 
        result += "Hours";
      else
        result += "Hour"
  }

  if (minutes > 0) {
      if (hours > 0) result += " ";
      result += `${minutes} `;
      if(minutes > 1)
        result += "Minutes";
      else
        result += "Minute";
  }

  return result;
}


export const isNotificationSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window